export enum EVENT_CODE {
  EV_01 = "TC-EV-01",
  EV_02 = "TC-EV-02",
}

export enum EVENT_ROUTE {
  LANTERN_FESTIVAL = "LanternFestival",
  THE_ICONIC_YEE_PENG_FESTIVAL_WORK_SHOP = "TheIconicYeePengFestivalWorkshop"
}

export const EVENT_ROUTES = {
  [EVENT_ROUTE.LANTERN_FESTIVAL]: EVENT_CODE.EV_01,
  [EVENT_ROUTE.THE_ICONIC_YEE_PENG_FESTIVAL_WORK_SHOP]: EVENT_CODE.EV_02,
}