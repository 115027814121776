import { Injectable } from "@angular/core";
import { ApiServiceService } from "./api-service.service";
import { Observable, of } from "rxjs";
import { EnvService } from "src/app/services/env.service";
import { EVENT_CODE, EVENT_ROUTE } from "../configs/event";

@Injectable({
  providedIn: "root",
})
export class EventsService {
  private path = "Website";
  private configs: any = {};

  constructor(
    private service: ApiServiceService,
    private envService: EnvService
  ) {
    let config = this.envService.getConfig();
    this.configs = config;
  }

  getEvents(payload: any): Observable<any> {
    const mockEvent = {
      messages: "Complete",
      status: true,
      data: this.getMockEventList()
    };
    return of(mockEvent);
    return this.service.callGet(`${this.path}/Events`, payload);
  }

  getEvent(payload: any): Observable<any> {
    const mockEvent = {
      messages: "Complete",
      status: true,
      data: this.getMockEventList()?.find(item => item.code === payload.code)
    };
    return of(mockEvent);
    return this.service.callGet(`${this.path}/Event/${payload.id}`, payload);
  }

  getMockEventList() {
    return [
      {
        id: 1,
        code: EVENT_CODE.EV_01,
        route: EVENT_ROUTE.LANTERN_FESTIVAL,
        title: "The Heaven Lantern Festival 2025",
        concept: "Exclusive Lantern Festival & Local Cultural Experience",
        short_description:
          "Come join us for an unforgettable experience that honors timeless traditions. Let the lanterns' enchantment, the music's rhythm, and the flavors of local cuisine whisk you away to a world ...",
        description: `
          <label><b>The Heaven Lantern Festival 2025 - Exclusive Lantern Festival & Local Cultural Experience</b></label><br/>
          <p>Step into a world of tradition and enchantment at The Heaven Lantern Festival, a one-of-a-kind celebration blending Chiang Mai\'s rich cultural heritage with an exclusive lantern experience. Set in the serene Mae Taeng area, just 40-60 minutes from Chiang Mai City, this intimate event offers a breathtaking spectacle as thousands of glowing lanterns illuminate the night sky.</p>
          <p>Beyond the mesmerizing lantern release, immerse yourself in authentic Lanna culture through traditional performances, hands-on activities, and a curated selection of local culinary delights. Savor the flavors of Northern Thailand with a variety of traditional dishes, including vegetarian and halal options, crafted to provide a true taste of the region.</p>
          <p>Join us for a once-in-a-lifetime experience that celebrates tradition, community, and the magic of the lantern festival in its most exclusive and meaningful form.</p>
          <br/>
          <label><b>Why Attend The Heaven Lantern Festival?</b></label>
          <p><b>Exclusive & Intimate Experience</b> - Unlike crowded public events, The Heaven Lantern Festival offers a serene and immersive setting, allowing you to fully embrace this magical tradition.</p>
          <p><b>Authentic Cultural Celebration</b> - Engage in Chiang Mai\'s rich Lanna heritage through traditional performances, hands-on cultural activities, and the mesmerizing lantern release.</p>
          <p><b>Local Culinary Delights</b> - Enjoy a curated selection of authentic Northern Thai cuisine, including vegetarian and halal options, for a true taste of the region.</p>
          <p><b>Scenic & Convenient Location</b> - Set in the tranquil Mae Taeng area, just 40-60 minutes from Chiang Mai City, providing the perfect backdrop for an unforgettable night.</p>
          <p><b>Unforgettable Moments</b> - Capture breathtaking memories as thousands of glowing lanterns light up the night sky, symbolizing hope, wishes, and new beginnings.</p>
          <p><b>A Once-in-a-Lifetime Experience</b> - Be part of an exclusive gathering that blends tradition, culture, and natural beauty into one extraordinary event.</p>
        `,
        booking_start: "2025-03-29 00:00:00",
        booking_end: "2025-11-06 12:00:00",
        start_date: "2025-11-05",
        end_date: "2025-11-06",
        video: {
          is_iframe: true,
          link: `<iframe src="https://www.youtube.com/embed/10RaQdWwDJs?si=iS9yPvObLar_Vmgr" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          // link: `${this.configs.SERVER_URL}videos/events/TC-EV-01/Heaven Lantern Final.mp4`,
        },
        document: "/assets/images/events/TC-EV-01/doc/Heaven Lantern 2025 - Information.pdf",
        // image: this.configs.SERVER_URL + "images/events/TC-EV-01/tc-ev-01-map.jpg",
        // image_cover: this.configs.SERVER_URL + "images/events/TC-EV-01/tc-ev-01-cover.jpg",
        // image_header: this.configs.SERVER_URL + "images/events/TC-EV-01/tc-ev-01-header.jpg",
        image: "/assets/images/events/TC-EV-01/tc-ev-01-description.jpg",
        image_cover: "/assets/images/events/TC-EV-01/tc-ev-01-cover.jpg",
        image_header: "/assets/images/events/TC-EV-01/tc-ev-01-cover.jpg",
        images: [
          {
            id: 1,
            url: "/assets/images/events/TC-EV-01/tc-ev-01-001.jpg",
          },
          {
            id: 2,
            url: "/assets/images/events/TC-EV-01/tc-ev-01-002.jpg",
          },
          {
            id: 3,
            url: "/assets/images/events/TC-EV-01/tc-ev-01-003.jpg",
          },
          {
            id: 4,
            url: "/assets/images/events/TC-EV-01/tc-ev-01-004.jpg",
          },
          {
            id: 5,
            url: "/assets/images/events/TC-EV-01/tc-ev-01-005.jpg",
          },
          {
            id: 6,
            url: "/assets/images/events/TC-EV-01/tc-ev-01-006.jpg",
          },
          {
            id: 7,
            url: "/assets/images/events/TC-EV-01/tc-ev-01-007.jpg",
          },
          {
            id: 8,
            url: "/assets/images/events/TC-EV-01/tc-ev-01-008.jpg",
          },
          {
            id: 9,
            url: "/assets/images/events/TC-EV-01/tc-ev-01-009.jpg",
          },
        ],
        type_name: "Seat",
        types: [
          {
            id: 1,
            type: "Standard",
            // detail:
            //   "1820 seats <br/>Entrance Ticket <br/>1 Krathong <br/>2 Sky Lanterns <br/>Khan Toke Dinner (Standard Area) <br/>Round Trip Transfer (Red Cab Taxi)",
            adult_price: 6700,
            child_price: 6700,
            infant_price: 0,
          },
          // {
          //   id: 2,
          //   type: "VIP",
          //   detail:
          //     "750 seats <br/>Entrance Ticket <br/>1 Krathong <br/>2 Sky Lanterns <br/>Khan Toke Dinner (VIP Area) <br/>Round Trip Transfer (Air-Conditioned Van)",
          //   adult_price: 5700,
          //   child_price: 5700,
          //   infant_price: 0,
          // },
        ],
        type_noted: {
          title: "How to get your e-ticket",
          noted: `
          After full payment is made, we will send an E-Ticket to your email within 24 hours. <br/>
          The E-Ticket will be used to register at the pick-up point and the registration counter at the event area.
        `,
          is_active: true,
        },
        schedules: [
          {
            id: 1,
            time: "01.30pm - 05.30pm",
            detail: `
              <b>Register and Ticket Redeem</b>
              <ul>
                <li>Register counter location at Central Chiang Mai on the 1st Floor, near the Information Counter</li>
                <li>The register will be available at 01.30 pm onward</li>
              </ul>
            `,
          },
          {
            id: 2,
            time: "03.30pm - 05.30pm",
            detail: `
              <b>Depart from Central Chiang Mai to Venue (45-60 mins)</b>
              <ul>
                <li>1<sup>st</sup> Shuttle will be leaving at 03.30 pm</li>
                <li>The last shuttle will be leaving at 05.30 pm</li>
              </ul>
            `
          },
          {
            id: 3,
            time: "06.00pm - 07.00pm",
            detail: `
              <b>Experience Pre-Dinner Activities</b>
              <ul>
                <li>Welcoming Zone - The Lantern Arch with performers</li>
                <li>Tribal Village Zone <i>(Tribal Products not include in the ticket price)</i></li>
                <li>Street Market</li>
                <li>Cultural Performances, Snacks & Drinks, DIYs, Showcases, Hands-On Activities</li>
                <li>Floating the Kratong into the pond both prepared and DIY Kratong</li>
              </ul>
            `
          },
          {
            id: 4,
            time: "07.00pm - 08.30pm",
            detail: `
              <b>Dinner at Main Dining Area</b>
              <ul>
                <li>The Cultural Parade inviting all guests to the dining area</li>
                <li>Enjoy a variety of free-flow food, desserts and drinks</li>
                <li>Performance with Light & Sound on the main stage</li>
                <li>Releasing Sky Lantern together</li>
              </ul>
            `
          },
          {
            id: 5,
            time: "09.00pm",
            detail: `
              <b>End of the Event</b>
              <ul>
                <li>Take the shuttle back to town and drop at Central Chiang Mai (45 mins)</li>
              </ul>
            `
          }
        ],
        schedules_noted: {
          title: "Remark",
          noted: "The schedule may change without notice due to weather, unforeseen circumstances, or management decisions to ensure the best experience for all attendees.",
          is_active: true,
        },
        include_title: "Ticket Include",
        includes: [
          {
            id: 1,
            detail: `Round-Trip Transfer by air-conditioned van`
          },
          {
            id: 2,
            detail: `Free Flow Lanna-Style Market & Dinner with Food and Drinks`,
          },
          {
            id: 3,
            detail: `Hands-On Art & Craft Workshops`,
          },
          {
            id: 4,
            detail: `Light & Sound Performance`
          },
          {
            id: 5,
            detail: `2 of Paper Sky Lanterns per person`,
          },
          {
            id: 6,
            detail: `1 of Kratong per person`,
          },
        ],
        exclude_title: "Ticket Exclude",
        excludes: [
          {
            id: 1,
            detail: `Hotel pick-up and drop-off to designed pick-up/drop-off point`
          },
          {
            id: 2,
            detail: `Tribal Products at Tribal Village Zone`
          }
        ],
        documents: {
          id: 1,
          file_url: "",
          is_active: true,
        },
        liabilities: {
          title: "Touring Center is an authorized ticket reseller for The Heaven Lantern Festival 2025. We want to ensure that you have a clear understanding of our responsibilities and limitations. Please review the following information:",
          details: [
            {
              title: "Our Responsibilities:",
              detail: `
                <b>Ticket Issuance:</b>
                <p>We are responsible for issuing the event tickets provided by the organizer, ensuring that you have a valid entry to The Heaven Lantern Festival 2025.</p>
                <b>Communication:</b>
                <p>We will communicate with you regarding the event's details, answering any questions you may have and providing relevant information to ensure a smooth experience.</p>
                <b>Payment Support:</b>
                <p>Out team is here to assist you with payments in accordance with the policy set forth by the organizer. We'll help facilitate a seamless payment process.</p>
              `,
            },
            {
              title: "Please Note, We Are Not Responsible For:",
              detail: `
                <b>Event Issues:</b>
                <p>While we facilitate ticket sales, we are not responsible for any issues that may arise at the event itself. This includes any services provided by the organizer, such as registration, transportation, and event-related services.</p>
                <b>Losses, Injuries, or Damages:</b>
                <p>We Cannot be held accountable for any losses, injuries, or damages you might incur during The Heaven Lantern 2025, whether they occur within the event or outside of the scheduled tour programs.</p>
                <b>Additional Expenses:</b>
                <p>Any additional expenses resulting from unforeseen circumstances, such as delays, accidents, natural disasters, political actions, or unrest, are the responsibility of clients. We are unable to cover these expenses.</p>
              `,
            },
          ],
          is_active: true,
        },
        details: {
          area: {
            title: "Event Area",
            image: "/assets/images/events/TC-EV-01/tc-ev-01-map.png",
            images: [],
            detail: ``,
            is_active: true,
          },
          seat: {
            title: "Seat Map",
            // image: this.configs.SERVER_URL + "images/events/TC-EV-01/details/seat-map.jpg",
            image: "/assets/images/events/TC-EV-01/tc-ev-01-seat.png",
            images: [],
            detail: ``,
            is_active: true,
          },
          map: {
            title: "Map to the event",
            // image: this.configs.SERVER_URL + "images/events/TC-EV-01/details/map.png",
            image: "/assets/images/events/TC-EV-01/tc-ev-01-map-to-event.png",
            images: [],
            detail: "",
            is_active: true,
          },
          ticket: {
            title: "E-Ticket",
            image: "/assets/images/events/TC-EV-01/tc-ev-01-ticket.png",
            images: [],
            detail: `
              <p>
                <b>Ticket included</b>
                <ul>
                  <li>Round-Trip Transportation by air-conditioned van</li>
                  <li>Free Flow Lanna-Style Market & Dinner with Food and Drinks</li>
                  <li>Hands-On Art & Craft Workshops</li>
                  <li>Light & Sound Performance</li>
                  <li>Two Paper Sky Lanterns per person</li>
                  <li>One Kratong per person</li>
                  <li>Travel accident insurance</li>
                </ul>
              </p>
              <p>
                <b>How to Receive Your E-Ticket</b>
                - After completing the full payment, we will send your E-Ticket to your email before the event begins.
                - Please present your E-Ticket at the meeting point to register and redeem your official ticket and wristband.
              </p>
            `,
            is_active: true,
          },
          point: {
            title: "Meeting Point for",
            image: "/assets/images/events/TC-EV-01/tc-ev-01-point-1.jpg",
            images: [
              {
                id: 1,
                url: "/assets/images/events/TC-EV-01/tc-ev-01-point-2.jpg",
                alt: "Meeting Point 2"
              },
              {
                id: 2,
                url: "/assets/images/events/TC-EV-01/tc-ev-01-point-3.jpg",
                alt: "Meeting Point 3"
              }
            ],
            detail: `
              <ul>
                <li><b>Pick up & Drop Off</b></li>
                <li><b>Register & Ticket Redeem</b></li>
              </ul>
              <p>At <a href="https://g.co/kgs/W5V7JsZ" target="_blank">Central Chiang Mai</a> (The 1st Floor, near the Information Counter)</p>
              <p>The register will be available at 01.30 pm</p>
            `,
            is_active: true,
          },
          transfer: {
            title: "Transfer Service",
            image: "/assets/images/events/TC-EV-01/tc-ev-01-transfer.jpg",
            images: [],
            detail: `
              <p>- 1st Shuttle will be leaving at 03.30 pm</p>
              <p>- The last shuttle will be leaving at 05.30 pm</p>
              <p>- Serviced by an air-conditioned van</p>
              <p>- Driving time : 45-60 minutes to/from the event</p>
            `,
            is_active: true,
          }
        },
        is_active: true,
        is_active_infant: true,
      },
      {
        id: 2,
        code: EVENT_CODE.EV_02,
        route: EVENT_ROUTE.THE_ICONIC_YEE_PENG_FESTIVAL_WORK_SHOP,
        title: "The Iconic Yee Peng Festival Workshop",
        short_description:
          "Experience the Iconic Yee Peng Festival with Local Lanna People.",
        description: `
          <p>Experience the Iconic Yee Peng Festival with Local Lanna People.</p>
          <p>Immerse yourself in the heart of Chiang Mai's cultural celebration at the Eiiga Yee Peng Pop Market.</p>
          <br/>

          <p>Dates: November 14-17, 2024</p>
          <p>Time: 9:00 AM - 7:00 PM</p>
          <p>Location: <a href="https://g.co/kgs/qLqLhmK" target="__blank">The Treasury Museum Chiang Mai</a></p>
          <br/>

          <p>Join us for a unique Yee Peng experience that combines tradition with sustainability.</p>
          <br/>

          <p><b>Choose from these exciting activities:</b></p>
          <br/>

          <div>
            <p>
              <img src="/assets/images/events/TC-EV-02/workshop-1.png" alt="Paint & Display Lanna Lantern">
            </p>
            <p><b>Workshop 1: Paint & Display Lanna Lantern (120 THB)</b></p>
            <p>Create a beautiful, eco-friendly Lanna lantern using sustainable materials crafted by local artisans.</p>
          </div>
          <br/>

          <div>
            <p>
              <img src="/assets/images/events/TC-EV-02/workshop-2.png" alt="Paint & Display Lanna Lantern (Exclusive)">
            </p>
            <p><b>Workshop 2: Paint & Display Lanna Lantern (Exclusive, 200 THB)</b></p>
            <p>Personalize your lantern with your name written in the ancient Lanna alphabet by a local master. Help preserve this fading cultural heritage.</p>
          </div>
          <br/>

          <div>
            <p>
              <img src="/assets/images/events/TC-EV-02/workshop-3.png" alt="Lanna Lantern Crafting Workshop">
            </p>
            <p><b>Workshop 3: Lanna Lantern Crafting Workshop (400 THB)</b></p>
            <p>Learn the art of Lanna lantern making from skilled local artisans and create your own unique lantern.</p>
          </div>
          <br/>

          <div>
            <p>
              <img src="/assets/images/events/TC-EV-02/workshop-4.png" alt="Traditional Lanna Dress Rental">
            </p>
            <p><b>Workshop 4: Traditional Lanna Dress Rental (800 THB)</b></p>
            <p>Step back in time and immerse yourself in the Yee Peng Festival by dressing in traditional Lanna attire.</p>
            <p>Explore the historic Old Town and capture unforgettable memories.</p>
          </div>
          <br/>

          <p>Don't miss this opportunity to celebrate Yee Peng with the locals and experience the magic of this ancient festival.</p>
          <br/>

          <div class="prices">
            <label><b>Workshop Price</b></label>
            <p>Workshop 1: Paint & Display Lanna Lantern = THB120 per person</p>
            <p>Workshop 2: Paint & Display Lanna Lantern (Exclusive) = THB200 per person</p>
            <p>Workshop 3: Lanna Lantern Crafting Workshop = THB400 per person</p>
            <p>Workshop 4: Traditional Lanna Dress Rental = THB800 per person</p>
          </div>
        `,
        booking_start: "2024-11-14 00:00:00",
        booking_end: "2024-11-17 17:00:00",
        start_date: "2024-11-15",
        end_date: "2024-11-17",
        video: null,
        document: null,
        image: null,
        image_cover: "/assets/images/events/TC-EV-02/tc-ev-02-cover.jpg",
        image_header: "/assets/images/events/TC-EV-02/tc-ev-02-header.jpg",
        images: [
          {
            id: 1,
            url: "/assets/images/events/TC-EV-02/tc-ev-02-001.jpg",
          },
          {
            id: 2,
            url: "/assets/images/events/TC-EV-02/tc-ev-02-002.jpg",
          },
          {
            id: 3,
            url: "/assets/images/events/TC-EV-02/tc-ev-02-003.jpg",
          },
          {
            id: 4,
            url: "/assets/images/events/TC-EV-02/tc-ev-02-004.jpg",
          },
          {
            id: 5,
            url: "/assets/images/events/TC-EV-02/tc-ev-02-005.jpg",
          },
          {
            id: 6,
            url: "/assets/images/events/TC-EV-02/tc-ev-02-006.jpg",
          },
          {
            id: 7,
            url: "/assets/images/events/TC-EV-02/tc-ev-02-007.jpg",
          },
          {
            id: 8,
            url: "/assets/images/events/TC-EV-02/tc-ev-02-008.jpg",
          },
        ],
        type_name: "Workshop",
        types: [
          {
            id: 1,
            type: "Workshop 1 : Paint & Display Lanna Lantern",
            detail: "Paint & Display Lanna Lantern",
            adult_price: 120,
            child_price: 120,
            infant_price: 0,
          },
          {
            id: 2,
            type: "Workshop 2 : Paint & Display Lanna Lantern (Exclusive)",
            detail: "Paint & Display Lanna Lantern (Exclusive)",
            adult_price: 200,
            child_price: 200,
            infant_price: 0,
          },
          {
            id: 3,
            type: "Workshop 3 : Lanna Lantern Crafting Workshop",
            detail: "Lanna Lantern Crafting Workshop",
            adult_price: 400,
            child_price: 400,
            infant_price: 0,
          },
          {
            id: 4,
            type: "Workshop 4 : Traditional Lanna Dress Rental",
            detail: "Traditional Lanna Dress Rental",
            adult_price: 800,
            child_price: 800,
            infant_price: 0,
          },
        ],
        type_noted: null,
        schedules: [],
        schedules_noted: null,
        includes: [],
        excludes: [],
        documents: null,
        liabilities: null,
        details: {
          seat: null,
          map: null,
          ticket: null,
        },
        is_active: true,
        is_active_infant: true,
      },
    ];
  }
}
