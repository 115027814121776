import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import {
  MatCheckboxModule,
  MAT_CHECKBOX_CLICK_ACTION,
} from "@angular/material/checkbox";
import {
  DatePipe,
  PathLocationStrategy,
  HashLocationStrategy,
  LocationStrategy,
} from "@angular/common";

import { WebsiteComponent } from "./pages/website/website.component";
import { HomeComponent } from "./pages/home/home.component";
import { ToursComponent } from "./pages/tours/tours.component";
import { KiewMaePanNaturalTrailComponent } from "./pages/tours/kiew-mae-pan-natural-trail/kiew-mae-pan-natural-trail.component";
import { BookingToursComponent } from "./pages/booking/booking-tours/booking-tours.component";
import { PaymentComponent } from "./pages/payment/payment.component";
import { ReceiptComponent } from "./pages/receipt/receipt.component";
import { UpdateComponent } from "./pages/update/update.component";
import { AboutComponent } from "./pages/about/about.component";
import { ActivitiesComponent } from "./pages/activities/activities.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { TourComponent } from "./pages/tours/tour/tour.component";
import { LoveOutLoudComponent } from "./pages/activities/csr-love-out-loud/love-out-loud.component";
import { BreathOfNatureComponent } from "./pages/activities/famtrip-breath-of-nature/breath-of-nature.component";
import { PromotionsComponent } from "./pages/promotions/promotions.component";
import { PartnersComponent } from "./pages/partners/partners.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { VanHireComponent } from "./pages/other-services/van-hire/van-hire.component";
import { BookingComponent } from "./pages/booking/booking-services/booking.component";
import { PageInternetBrowersComponent } from "./pages/page-internet-browers/page-internet-browers.component";

import { LoginComponent } from "./pages/login/login.component";
import { DashboardComponent } from "./pages/managements/dashboard/dashboard.component";
import { CloseBookingTourComponent } from "./pages/managements/close-booking-tour/close-booking-tour.component";
import { TermsAndConditionsComponent } from "./pages/terms-and-conditions/terms-and-conditions.component";
import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";
import { EventsComponent } from "./pages/events/events.component";
import { EventComponent } from "./pages/event/event.component";
import { BookingEventComponent } from "./pages/booking-event/booking-event.component";

import { PaymentEventComponent } from "./pages/payment-event/payment-event.component";
import { ReceiptEventComponent } from "./pages/receipt-event/receipt-event.component";
import { ReviewAndVisitComponent } from "./pages/review-and-visit/review-and-visit.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: 'home', redirectTo: "" },
  //! Tour
  { path: "tours", component: ToursComponent },
  { path: "tour/:id", component: TourComponent },
  {
    path: "tours/kiew-mae-pan-natural-trail",
    component: KiewMaePanNaturalTrailComponent,
  },
  { path: "booking", component: BookingToursComponent },
  { path: "payment/:id", component: PaymentComponent },
  { path: "receipt/:id", component: ReceiptComponent },
  { path: "update/booking/:id", component: UpdateComponent },

  //! Event
  { path: "events", component: EventsComponent },
  {
    path: "event/:id",
    component: EventComponent,
    data: {
      title: "Ticket to The Heaven Lantern Festival 2024, Chiang Mai",
      description: "Experience the Lantern Festival, inclusive of round-trip transfer, event entrance ticket, 1 Krathong, 2 Sky lanterns, and a traditional Khan Toke Dinner",
      keyword:
        "krathong festival chiang mai,yi peng lantern festival,lantern festival,lantern festival chiangmai,lantern festival chiang mai,sky lantern festival,floating lantern festival,loy krathong 2024,the heaven lantern festival 2024,heaven lantern festival",
    },
  },
  { path: "event/booking/:id", component: BookingEventComponent },
  { path: "event/payment/:id", component: PaymentEventComponent },
  { path: "event/receipt/:id", component: ReceiptEventComponent },

  { path: "about", component: AboutComponent },
  { path: "activities", component: ActivitiesComponent },
  { path: "contact", component: ContactComponent },
  { path: "love-out-loud", component: LoveOutLoudComponent },
  { path: "breath-of-nature", component: BreathOfNatureComponent },
  { path: "promotions", component: PromotionsComponent },
  { path: "partners", component: PartnersComponent },
  { path: "vanhire/:id", component: VanHireComponent },
  { path: "services/booking", component: BookingComponent },
  { path: "page-detect", component: PageInternetBrowersComponent },
  { path: "login", component: LoginComponent },
  { path: "dashboard", component: DashboardComponent },
  { path: "close-booking-tour", component: CloseBookingTourComponent },
  { path: "terms-and-conditions", component: TermsAndConditionsComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "privacy-policy/:type", component: PrivacyPolicyComponent },
  {
    path: "reviews-and-visits",
    component: ReviewAndVisitComponent,
    data: {
      hideLayout: true
    }
  },
  { path: "**", component: PageNotFoundComponent },
];

// const devMode = (window.location.href).split('.com')[0] + '.com' === URL.DEV;
// const locationStrategyClass = devMode ? HashLocationStrategy : PathLocationStrategy;

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      // useHash: false,
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
    }),
  ],
  providers: [
    { provide: MAT_CHECKBOX_CLICK_ACTION, useValue: "check" },
    // { provide: LocationStrategy, useClass: devMode ? HashLocationStrategy : PathLocationStrategy },
    // { provide: LocationStrategy, useClass: PathLocationStrategy },
    // { provide: LocationStrategy, useClass: HashLocationStrategy }, // hashtag
  ],

  exports: [RouterModule],
})
export class AppRoutingModule {}
