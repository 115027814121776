import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import { NgxPopper } from 'angular-popper';
import { OwlModule } from 'ngx-owl-carousel';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { MatSelectModule } from '@angular/material/select';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MatCheckboxModule , MAT_CHECKBOX_CLICK_ACTION} from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { UixMatNumberSpinnerModule } from "uix-mat-number-spinner";
import { NgxNumberSpinnerModule } from 'ngx-number-spinner';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormsModule } from '@angular/forms';
import { NgxPayPalModule } from 'ngx-paypal';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {MatListModule } from '@angular/material/list';
import { NgxPaginationModule } from 'ngx-pagination';
import { DatePipe, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common'
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { FacebookModule } from 'ngx-facebook';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

// import { SmoothScrollToDirective, SmoothScrollDirective } from 'ng2-smooth-scroll';

import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './pages/home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { WebsiteComponent } from './pages/website/website.component';
import { HeaderComponent } from './pages/header/header.component';
import { FooterComponent } from './pages/footer/footer.component';
import { ToursComponent } from './pages/tours/tours.component';
import { KiewMaePanNaturalTrailComponent } from './pages/tours/kiew-mae-pan-natural-trail/kiew-mae-pan-natural-trail.component';
import { BookingToursComponent } from './pages/booking/booking-tours/booking-tours.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { ReceiptComponent } from './pages/receipt/receipt.component';
import { UpdateComponent } from './pages/update/update.component';
import { AffiliateComponent } from './pages/affiliate/affiliate.component';
import { AboutComponent } from './pages/about/about.component';
import { ActivitiesComponent } from './pages/activities/activities.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HeaderNonFixedComponent } from './pages/header-non-fixed/header-non-fixed.component';
import { TourComponent } from './pages/tours/tour/tour.component';
import { LoveOutLoudComponent } from './pages/activities/csr-love-out-loud/love-out-loud.component';
import { BreathOfNatureComponent } from './pages/activities/famtrip-breath-of-nature/breath-of-nature.component';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { PromotionsComponent } from './pages/promotions/promotions.component';
import { PartnersComponent } from './pages/partners/partners.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { OtherServicesComponent } from './pages/other-services/other-services.component';
import { VanHireComponent } from './pages/other-services/van-hire/van-hire.component';
import { VansComponent } from './pages/other-services/vans/vans.component';
import { BookingComponent } from './pages/booking/booking-services/booking.component';
import { PageInternetBrowersComponent } from './pages/page-internet-browers/page-internet-browers.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/managements/dashboard/dashboard.component';
import { CloseBookingTourComponent } from './pages/managements/close-booking-tour/close-booking-tour.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';

import { TermAndConditionComponent } from './shared/terms-and-conditions/terms-and-conditions.component';
import { GlobalService } from './helpers/global.service';
import { AcceptCookiesComponent } from './shared/accept-cookies/accept-cookies.component';
import { CustomService } from './services/custom.service';
import { EnvService } from './services/env.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { PrivacyPolicyEnComponent } from './shared/privacy-policy/privacy-policy-en/privacy-policy-en.component';
import { PrivacyPolicyThComponent } from './shared/privacy-policy/privacy-policy-th/privacy-policy-th.component';
import { EventsComponent } from './pages/events/events.component';
import { EventComponent } from './pages/event/event.component';
import { BookingEventComponent } from './pages/booking-event/booking-event.component';
import { PaymentEventComponent } from './pages/payment-event/payment-event.component';
import { ReceiptEventComponent } from './pages/receipt-event/receipt-event.component';
import { TermsAndConditionsEventComponent } from './shared/terms-and-conditions-event/terms-and-conditions-event.component';
import { ReviewAndVisitComponent } from './pages/review-and-visit/review-and-visit.component';

const routes: Routes = []; // sets up routes constant where you define your routes

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    WebsiteComponent,
    HeaderComponent,
    FooterComponent,
    ToursComponent,
    KiewMaePanNaturalTrailComponent,
    BookingToursComponent,
    PaymentComponent,
    ReceiptComponent,
    UpdateComponent,
    AffiliateComponent,
    AboutComponent,
    ActivitiesComponent,
    ContactComponent,
    HeaderNonFixedComponent,
    TourComponent,
    LoveOutLoudComponent,
    BreathOfNatureComponent,
    PromotionsComponent,
    PartnersComponent,
    PageNotFoundComponent,
    OtherServicesComponent,
    VanHireComponent,
    VansComponent,
    BookingComponent,
    PageInternetBrowersComponent,
    LoginComponent,
    DashboardComponent,
    CloseBookingTourComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    TermAndConditionComponent,
    ReviewAndVisitComponent,
    AcceptCookiesComponent,
    PrivacyPolicyEnComponent,
    PrivacyPolicyThComponent,
    EventsComponent,
    EventComponent,
    BookingEventComponent,
    PaymentEventComponent,
    ReceiptEventComponent,
    TermsAndConditionsEventComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxPopper,
    NgbModule,
    OwlModule,
    CarouselModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    HttpModule,
    HttpClientModule,
    MatSelectModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    MatCheckboxModule,
    MatRadioModule,
    UixMatNumberSpinnerModule,
    NgxNumberSpinnerModule,
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    FormsModule,
    NgxPayPalModule,
    PdfViewerModule,
    MatListModule,
    NgxPaginationModule,
    ScrollToModule.forRoot(),
    NgxScrollTopModule,
    FacebookModule.forRoot(),
    TimepickerModule.forRoot()
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (envConfigService: EnvService,) => () =>
        envConfigService.load(),
      deps: [EnvService],
      multi: true,
    },
    GlobalService,
    DatePipe,
    // CustomService,
    // {provide: MAT_CHECKBOX_CLICK_ACTION, useValue: 'check'}, // no hashtag
    // {provide: LocationStrategy, useClass: HashLocationStrategy}, // hashtag
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB',
    },
    // {
    //   provide: LocationStrategy,
    //   useClass: PathLocationStrategy
    // },
  ],
  bootstrap: [AppComponent],
  exports: [AppRoutingModule],
  schemas:[NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
