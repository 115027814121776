import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

interface ReviewAndVisit {
  id: number;
  name: string;
  link: string;
  mobileLink: string;
  isNewTab?: boolean;
}

@Component({
  selector: 'app-review-and-visit',
  templateUrl: './review-and-visit.component.html',
  styleUrls: ['./review-and-visit.component.scss']
})
export class ReviewAndVisitComponent implements OnInit {

  reviewData: ReviewAndVisit[] = [];
  visitData: ReviewAndVisit[] = [];

  constructor(private route: Router) { }

  ngOnInit(): void {
    this.reviewData = [
      {
        id: 1,
        name: "Facebook",
        link: "https://www.facebook.com/toursinchiangmai/reviews",
        mobileLink: "fb://page/676226475750588",
      },
      {
        id: 2,
        name: "Tripadvisor",
        link: "https://th.tripadvisor.com/UserReviewEdit-g293917-d5213271-Touring_Center-Chiang_Mai.html",
        mobileLink: "tripadvisor://www.tripadvisor.com/UserReviewEdit-g293917-d5213271-Touring_Center-Chiang_Mai.html",
      },
      {
        id: 3,
        name: "Google",
        link: "https://g.page/r/CV68lJpFHhXGEAI/review",
        mobileLink: "https://g.page/r/CV68lJpFHhXGEAI/review",
      }
    ];

    this.visitData = [
      {
        id: 1,
        name: "Website",
        link: "/",
        mobileLink: "/",
      },
      {
        id: 2,
        name: "Youtube",
        link: "https://www.youtube.com/channel/UCqx6cadulg7se3n-991yDrA",
        mobileLink: "vnd.youtube://channel/UCqx6cadulg7se3n-991yDrA",
      },
      {
        id: 3,
        name: "WhatsApp",
        link: "https://wa.me/66882585817",
        mobileLink: "whatsapp://66882585817",
      },
      {
        id: 4,
        name: "LINE",
        link: "https://line.me/ti/p/~touringcnx.com",
        mobileLink: "line://touringcnx.com",
      }
    ];

  }

  onClickLink(data: ReviewAndVisit) {
    const link = data.link;
    const mobileLink = data.mobileLink;

    if (link === '/') {
      this.route.navigate([link]);
      return;
    }

    this.openApp(mobileLink, link);
  }

  openApp(appURL: string, webURL: string) {
    if (this.isMobileDevice()) {
      window.location.href = appURL;

      setTimeout(() => {
        window.location.href = webURL;
      }, 1000);
    } else {
      window.location.href = webURL;
    }
  }

  isMobileDevice() {
    return /android|iphone|ipad|ipod|mobile/i.test(navigator.userAgent);
  }

}
