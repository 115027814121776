import { Component, OnInit, Output, Input, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { Meta } from "@angular/platform-browser";
import { Http, RequestOptions } from "@angular/http";
// import { FacebookService, InitParams } from "ngx-facebook";
import { DeviceDetectorService } from "ngx-device-detector";
import { MetaDataService } from "./helpers/meta-data.service";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "touringcenter";
  ipAddress: string;
  deviceInfo = null;

  public browser_variable: boolean = false;
  private routerSubscription: any;
  isUseLayout: boolean = true;

  constructor(
    private router: Router,
    private metaTagService: Meta,
    // private facebookService: FacebookService,
    private http: Http,
    private deviceService: DeviceDetectorService,
    private metaDataService: MetaDataService,
  ) {
    this.epicFunction();
  }

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (
      this.deviceInfo.browser == "FBAN" ||
      this.deviceInfo.browser == "FBAV/" ||
      this.deviceInfo.browser == "Line/" ||
      this.deviceInfo.browser == "Line" ||
      this.deviceInfo.browser == "facebook" ||
      this.deviceInfo.browser == "line"
    ) {
      this.router.navigate(["/page-detect"]);
    }
  }

  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 50); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  // private initFacebookService(): void {
  //   const initParams: InitParams = { xfbml: true, version: "v3.2" };
  //   this.facebookService.init(initParams);
  // }

  ngOnInit(): void {
    // this.initFacebookService();
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    // Detect Browser
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        this.browser_variable = false; // Chrome
      } else {
        this.browser_variable = true; // Safari
      }
    }

    this.metaTagUpdate();

    // // -------- ADD META ----------------------------------------
    this.metaTagService.addTags(
      [
        {
          name: "title",
          property: "og:title",
          content:
            "Tour in Chiang Mai and around &amp; Activities with Premium Service : Touring Center",
        },
        {
          name: "description",
          property: "og:description",
          content:
            "Explore and Experience tour in Chiang Mai and around from professional team with the unbeatable service. Touring Center provide a daily departure day tour and package for both join with group (small size) and private trip.",
        },
        {
          name: "author",
          content: "Touring Center",
        },
        {
          name: "keywords",
          content:
            "Tour in Chiang Mai, Day Tour, Chiang Mai Tour, Day Trip, Join Premium, Join Tour, Private Tour, Touring Center, Yi Peng Chiang Mai, chiang mai tour company, Chiang Mai, small group",
        },
        {
          name: "image",
          property: "og:image",
          content:
            "https://touringcenter.com/assets/images/TC/logo-tc-52549811-1.png",
        },
        {
          name: "url",
          property: "og:url",
          content: "https://touringcenter.com/",
        },
        {
          name: "subject",
          content: "Tour Company in Chiang Mai",
        },
        {
          name: "type",
          property: "og:type",
          content: "business.business",
        },
        {
          name: "site_name",
          property: "og:site_name",
          content:
            " Tour in Chiang Mai and around &amp; Activities with Premium Service : Touring Center",
        },
        {
          charset: "UTF-8",
        },
      ],
      true
    );

    // Detect change route and set meta tag
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const route = this.router.routerState.snapshot.root.firstChild;
      this.checkActiveLayout(route);
      this.updateMetaTags(route);
    });
  }

  private checkActiveLayout(route: any): void {
    if (route && route.data) {
      this.isUseLayout = !route.data['hideLayout'];
    } else {
      this.isUseLayout = true;
    }
  }

  private updateMetaTags(route: any): void {
    if (route && route.data) {
      const metaTitle = route.data['title'] || 'Tour in Chiang Mai and around &amp; Activities with Premium Service : Touring Center';
      const metaDescription = route.data['description'] || 'Explore and Experience tour in Chiang Mai and around from professional team with the unbeatable service. Touring Center provide a daily departure day tour and package for both join with group (small size) and private trip.';
      const metaKeyword = route.data['keyword'] || 'Tour in Chiang Mai, Day Tour, Chiang Mai Tour, Day Trip, Join Premium, Join Tour, Private Tour, Touring Center, Yi Peng Chiang Mai, chiang mai tour company, Chiang Mai, small group';

      // this.metaDataService.setTitle(metaTitle);
      this.metaDataService.setMetaTag('title', metaTitle);
      this.metaDataService.setMetaTag('description', metaDescription);
      this.metaDataService.setMetaTag('keywords', metaKeyword);
    }
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  metaTagUpdate() {
    this.metaTagService.updateTag({
      name: "title",
      property: "og:title",
      content:
        "Tour in Chiang Mai and around &amp; Activities with Premium Service : Touring Center",
    });
    this.metaTagService.updateTag({
      name: "description",
      property: "og:description",
      content:
        "Explore and Experience tour in Chiang Mai and around from professional team with the unbeatable service. Touring Center provide a daily departure day tour and package for both join with group (small size) and private trip.",
    });
    this.metaTagService.updateTag({
      name: "keywords",
      content:
        "Tour in Chiang Mai, Day Tour, Chiang Mai Tour, Day Trip, Join Premium, Join Tour, Private Tour, Touring Center, Yi Peng Chiang Mai, chiang mai tour company, Chiang Mai, small group",
    });
    this.metaTagService.updateTag({
      name: "image",
      property: "og:image",
      content:
        "https://touringcenter.com/assets/images/TC/logo-tc-52549811-1-sh.png",
    });
    this.metaTagService.updateTag({
      name: "url",
      property: "og:url",
      content: "https://touringcenter.com/",
    });
  }
}
