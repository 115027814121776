<div class="container">
  <div class="px-4 py-5 mb-5 bg-white rounded">
    <!-- Header -->
    <section class="header-wrapper text-center mt-2 mb-4">
      <img class="logo" src="/assets/images/TC/LOGO-MADAME.png">
      <h2 class="fw-700 mt-1">
        Premium Day Trips - Chiang Mai & Beyond
      </h2>
    </section>

    <!-- Review -->
    <section class="review-wrapper mt-2">
      <h2 class="fw-500">
        Share Your Experience:
      </h2>
      <ng-container *ngIf="reviewData">
        <button *ngFor="let data of reviewData" class="btn btn-dark w-100" (click)="onClickLink(data)">
          {{ data?.name }}
        </button>  
      </ng-container>
    </section>

    <!-- Visit -->
    <section class="visit-wrapper mt-2">
      <h2 class="fw-500">
        Stay Connected:
      </h2>
      <ng-container *ngIf="visitData">
        <button *ngFor="let data of visitData" class="btn btn-dark w-100" (click)="onClickLink(data)">
          {{ data?.name }}
        </button>  
      </ng-container>
    </section>
  </div>
</div>