<ng-container *ngIf="isUseLayout">
  <app-header></app-header>
</ng-container>
<!-- <app-header-non-fixed></app-header-non-fixed> -->

<div class="content-wrapper">
  <router-outlet
    (activate)="onActivate($event)"
    [class.browser_change]="browser_variable"
  ></router-outlet>
</div>

<ngx-scrolltop position="left" backgroundColor="#FF6961"></ngx-scrolltop>
<div
  class="fb-customerchat"
  page_id="676226475750588"
  greeting_dialog_display="show"
  size="standard"
></div>

<ng-container *ngIf="isUseLayout">
  <app-footer></app-footer>
</ng-container>

<app-accept-cookies></app-accept-cookies>
<!-- greeting_dialog_display="hide" -->
<!-- <router-outlet [class.browser_change]="browser_variable"></router-outlet> -->
